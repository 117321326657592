<!-- 收益趋势 -->
<template>
    <div class="a-mt-36">
        <div class="a-flex-rsbc">
            <div class="a-flex-rfsfe">
                <span class="a-c-master a-fw-700 a-fs-16">收益趋势</span>
                <span class="a-c-normal a-fs-14 a-ml-10">(单位: 元)</span>
            </div>
            <le-date-range 
                @change="dateChange"
                ref="dateRange"
                :pickerOptions="pickerOptions" 
                :minDate.sync="queryStartDate" 
                :maxDate.sync="queryEndDate" 
                :defaultTime="['00:00:00','23:59:59']"
                valueFormat="yyyy-MM-dd HH:mm:ss" />
        </div>
        <div class="a-flex-rfsc a-mt-25">
            <div class="a-flex-cfsfs" style="margin-right:107px">
                <span class="a-fs-14 a-c-normal">搜索范围交易额（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ transition | initMoney }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs" style="margin-right:107px">
                <span class="a-fs-14 a-c-normal">搜索范围收益额（元）</span>
                <div class="a-flex-rfsb a-mt-10 familyDin">
                    <span class="a-fs-24 a-c-master">￥</span>
                    <span class="a-fs-32 a-c-master a-fw-700">{{ moneyNum | initMoney }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs">
                <span class="a-fs-14 a-c-normal">搜索范围订单量（笔）</span>
                <span class="a-fs-32 a-c-master a-mt-10 a-fw-700 familyDin">{{ orderNum || 0 }}</span>
            </div>
        </div>
        <!-- <div class="a-flex-rfec a-mt-16">
            <div class="a-flex-rfsc">
                <div class="tips2 "></div>
                <span class="a-c-normal a-fs-14 a-ml-11">收益额（元）</span>
            </div>
            <div class="a-flex-rfsc a-ml-40">
                <div class="tips3 "></div>
                <span class="a-c-normal a-fs-14 a-ml-11">交易额（元）</span>
            </div>
            <div class="a-flex-rfsc a-ml-40">
                <div class="tips "></div>
                <span class="a-c-normal a-fs-14 a-ml-11">订单量（笔）</span>
            </div>
        </div> -->
        <div id="mychart" class="mychart "></div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                queryStartDate: '',
                queryEndDate: '',
                moneyNum: '',//
                orderNum: '',
                transition: '',
                params_req: '',
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) {
                            this.choiceDate = ''
                        }
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            let one = 31 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
            }
        },
        created () {
            
        },
        props: ['id','params','infoType'],
        watch:{
            params:{
                immediate: true,
                handler (val) {                    
                    if(val && JSON.stringify(val) != '{}'){
                        this.params_req = JSON.parse(JSON.stringify(val))
                        this.$nextTick(()=>{
                            if(this.infoType == 'province' || this.infoType == 'city'){
                                this.queryStartDate = this.$getDay.getTodayBeforeDays(31) + ' 00:00:00'
                                this.queryEndDate = this.$getDay.getTodayBeforeDays(1) + ' 23:59:59'
                            }else{
                                this.queryStartDate = this.$getDay.getTodayBeforeDays(30) + ' 00:00:00'
                                this.queryEndDate = this.$getDay.getTodayBeforeDays(0) + ' 23:59:59'
                            }
                            this.$refs['dateRange'].value1 = [this.queryStartDate, this.queryEndDate]
                            this.getChartData()
                        })
                    }
                }
            }
        },
        filters:{
            initMoney (val) {
                var num = Number(val);
                if(!num){//等于0
                    return num+'.00';
                }else{//不等于0
                    num = Math.round((num)*100)/10000;
                    num = num.toFixed(2);
                    num+='';//转成字符串
                    var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
                // console.log(num.indexOf('.')>-1)
                    return num.replace(reg, '$1,')//千分位格式化
                }
            }
        },
        methods:{
            dateChange () {
                this.getChartData()
            },
            setMoney (val) {
                var num = Number(val);
                if(!num){//等于0
                    return num+'.00';
                }else{//不等于0
                    // num = Math.round((num)*100)/10000;
                    // num = num.toFixed(2);
                    num+='';//转成字符串
                    var reg=num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;//千分符的正则
                // console.log(num.indexOf('.')>-1)
                    return num.replace(reg, '$1,')//千分位格式化
                }
            },
            drawChart (xAxis=[], dataTrading=[], dataOrder=[], transactionAmount=[]) { //横坐标, 收益金额，订单数量，交易额
                var chartDom = document.getElementById('mychart');
                var option;
                option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    color: ['#FFA900','#67C23A','#6699FF'],
                    legend: {
                        itemWidth: 8,
                        itemHeight: 8,
                        right: "2%",
                        itemGap: 40
                    },
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        top: '36px',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: '#DDDDDD'
                            }
                        },
                        splitLine:{
                            show:false
                        },
                        axisLabel:{
                            color: '#999999',
                            showMaxLabel: true,
                            formatter: function (value, index) {
                                let arr = value.split('-')
                                return arr[1]+'-'+arr[2];
                            }
                        },
                        data: xAxis
                    },
                    yAxis: {
                        type: 'value',
                        max: 'dataMax',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine:{
                            lineStyle:{
                                color: '#EEEEEE',
                                type: 'dashed'
                            }
                        },
                    },
                    series: [
                        {
                            name: '收益额（元）',
                            data: dataTrading,
                            type: 'line',
                            lineStyle:{
                                normal:{
                                    color: '#FFA900',
                                    width: 2
                                }
                            },
                            smooth: true
                        },
                        {
                            name: '交易额（元）',
                            data: transactionAmount,
                            type: 'line',
                            lineStyle:{
                                normal:{
                                    color: '#67C23A',
                                    width: 2
                                }
                            },
                            smooth: true
                        },
                        {
                            name: '订单量（笔）',
                            data: dataOrder,
                            type: 'line',
                            lineStyle:{
                                normal:{
                                    color: '#6699FF',
                                    width: 2
                                }
                            },
                            smooth: true
                        }
                    ]
                };
                var myChart = this.$echarts.init(chartDom);
                myChart.clear()
                myChart.setOption(option,true);
            },
            getTotal () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getStatOrderAmountTotal,
                    method: "post",
                    showLoading: false,
                    params: {
                        ...this.params_req,
                        queryStartDate: this.queryStartDate,
                        queryEndDate: this.queryEndDate
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.moneyNum = res.result.data.thisTimeOrderAmount
                        this.orderNum = res.result.data.thisTimeOrderNum
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            // 获取图表数据
            getChartData () {
                console.log('开始请求收益趋势');
                let apiUrl
                if(this.infoType == 'business'){
                    apiUrl = this.$Config.apiUrl.getAnalysisIncomeTrendBusiness
                }else if(this.infoType == 'income'){
                    apiUrl = this.$Config.apiUrl.getAnalysisIncomeTrendIncome
                }else if(this.infoType == 'station'){
                    apiUrl = this.$Config.apiUrl.getAnalysisIncomeTrendStation
                }else if(this.infoType == 'province'){
                    apiUrl = this.$Config.apiUrl.getAnalysisIncomeTrendCity
                }else if(this.infoType == 'city'){
                    apiUrl = this.$Config.apiUrl.getAnalysisIncomeTrendCity
                }else{
                    apiUrl = this.$Config.apiUrl.getStationIncomeTrend
                }
                this.$Axios._post({
                    url: apiUrl,
                    method: "post",
                    showLoading: false,
                    params: {
                        ...this.params_req,
                        startDay: this.queryStartDate,
                        endDay: this.queryEndDate,
                        startTime: this.queryStartDate,
                        endTime: this.queryEndDate
                    }
                }).then(res => {
                    if(res.result.code == 0){           
                        this.moneyNum = res.result.data.allIncome
                        this.transition = res.result.data.allTransaction 
                        this.orderNum = res.result.data.allNumber 
                        let dateArr
                        if(res.result.data.incomeData.length){
                            dateArr = res.result.data.incomeData
                        }else{
                            dateArr = this.$getDay.enumerateDaysBetweenDates(this.queryStartDate,this.queryEndDate).map(item=>{
                                return {
                                    day: item,
                                    incomeAmount: 0,
                                    transactionAmount: 0,
                                    orderNum: 0
                                }
                            })
                        }
                        let xAxis = []
                        let dataTrading = []
                        let dataOrder = []
                        let transactionAmount = []
                        dateArr.map(item=>{
                            xAxis.push(item.day)
                            dataTrading.push(Number((item.incomeAmount/100).toFixed(2)))
                            transactionAmount.push(Number((item.transactionAmount/100).toFixed(2)))
                            dataOrder.push((item.orderNum || item.number) || 0)
                        })
                        this.drawChart(xAxis, dataTrading, dataOrder, transactionAmount)
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.tips3{
    width: 11px;
    height: 11px;
    background: #67C23A;
    border-radius: 3px;
}
.tips2{
    width: 11px;
    height: 11px;
    background: #FFA900;
    border-radius: 3px;
}
.tips{
    width: 11px;
    height: 11px;
    background: #6699FF;
    border-radius: 3px;
}
.mychart{
    width: 100%;
    height: 300px;
}
.tooltips{
    background: #FFFFFF;
    box-shadow: 0px 5px 21px 0px rgba(139,147,167,0.25);
    opacity: 0.96;
}
.tooltipsTitle{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606366;
    line-height: 27px;
    padding: 0 25px;
}
.tooltipsContent{
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #303133;
    line-height: 32px;
}
</style>