<!-- 站点情况 -->
<template>
    <div class="a-mt-60">
        <div class="a-flex-rsbc a-pt-25 headBox">
            <div class="a-flex-rfsfe">
                <span class="a-c-master a-fw-700 a-fs-16">站点情况</span>
            </div>
            <le-date-range 
                ref="dateRangeAna"
                :minDate.sync="startDay" 
                :maxDate.sync="endDay" 
                :clearable="false"
                :defaultTime="['00:00:00','23:59:59']"
                valueFormat="yyyy-MM-dd HH:mm:ss" />
        </div>
        <analysis-station :isFixed="false" :isSticky="false" :isSearch="false" :tableLoading="true" :pageParam.sync="pageParam"></analysis-station>
    </div>
</template>

<script>
    import AnalysisStation from '../child/analysis-station.vue';
    export default {
        components: { AnalysisStation },
        data() {
            return {
                pageParam: {
                    url: this.$Config.apiUrl.getAnalysisStationPage,
                    method: "post",
                    params: {
                        companyId: '',
                        incomeId: this.params.incomeId,
                        stationId: '',
                        stationIdList: [],
                        provinceCode: '',
                        cityCode: '',
                        areaCode: '',
                        startDay: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
                        endDay: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
                        tagId: ''
                    },
                    showLoading: false,
                    freshCtrl: 1,
                },
                startDay: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
                endDay: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59'
            };
        },
        computed:{
            dateStr () {
                return this.startDay + this.endDay
            }
        },
        watch:{
            dateStr:{
                handler (val) {
                    this.pageParam.params = {
                        companyId: '',
                        incomeId: this.params.incomeId,
                        stationId: '',
                        stationIdList: [],
                        provinceCode: '',
                        cityCode: '',
                        areaCode: '',
                        startDay: this.startDay,
                        endDay: this.endDay
                    }
                    this.pageParam.freshCtrl++;
                }
            }
        },
        mounted() {

        },
        props: ['params']
    }
</script>

<style lang="scss" scoped>
.border-lt{
    border-left: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
}
.border-rt{
    border-right: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
}
.border-lb{
    border-left: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
}
.border-rb{
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    
}
.border-r{
    border-right: 1px solid #E3E3E3;
}
</style>